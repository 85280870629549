<template>
  <div fp-not-support :class="['field-wrapper']">
    <FontIcon :name="icon" />
    <p class="desc" v-html="desc"></p>
    <a class="link" :href="hrefText">{{ csEmail }}</a>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'FpNotSupport',
  components: { FontIcon },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    icon() { return 'info-circle-line'; },
    desc() { return this.$t('forgotPassword.description.notCustomerService'); },
    hrefText() {return `mailTo:${this.csEmail}`;},
    isMBP() {
      return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site);
    },
  },
  mounted() {
    if (!this.isMBP) {
      this.replaceRouteName('FpInfo');
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
.GOLD {
  .link {color: #a98545 !important;}
}
[fp-not-support] { .tc(); .flex();.flex-dc(); .justify-center(); .items-start();
  [font-icon] { .block(); .fs(30); .c(white); .pb(10); }
  .desc {margin: 0 !important; .c(#AEAEAE); .fs(16);}
  .link { font-weight: 600; .fs(16); .c(#c64040); text-decoration-line: underline; }
}
</style>